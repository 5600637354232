import React from "react";
import { motion } from "framer-motion";
import { useWpAbout } from "../hooks/wp/useWpAbout";
import RawHtml from "./RawHtml";

const animation = {
  open: {
    y: 0,
  },
  close: {
    y: "100vh",
  },
};

const AboutBox = ({ isOpen }) => {
  const { wpPage } = useWpAbout();

  return (
    <>
      <motion.div
        variants={animation}
        initial="close"
        animate={isOpen ? "open" : "close"}
        exit="close"
        className="fixed w-full top-0 left-0 bg-black z-[1] h-[100%] overflow-scroll pb-12 lg:pb-8"
        transition={{ type: "tween", ease: [0.33, 1, 0.68, 1], duration: 0.4 }}
      >
        <div className="grid md:grid-cols-2 lg:grid-cols-about-md md:gap-x-12 lg:gap-x-22 bg-black pt-[calc(40px+8em)] px-4 md:px-6 pb-20 sm:pb-0 ">
          <Column
            header="Design Approach"
            content={wpPage.aboutFields.designApproach}
            classNames="lg:w-11/12 col-span-1 row-span-2"
          />

          <Column
            header="Clients"
            content={wpPage.aboutFields.clients}
            classNames="pt-16 md:pt-0 col-span-1 row-span-2"
          />

          <Column
            header="Expertises"
            content={wpPage.aboutFields.expertises}
            classNames="pt-16 lg:pt-0 col-span-1 row-span-1"
          />

          <Column
            header="Contacts"
            content={wpPage.aboutFields.contact}
            classNames="pt-16 lg:pt-0 col-span-1"
          />
          <Column
            header="Works featured on"
            content={wpPage.aboutFields.worksFeaturedOn}
            classNames="pt-16 lg:pt-0 col-span-1 row-span-1"
          />
          <Column
            header="Credits"
            content={wpPage.aboutFields.credits}
            classNames="pt-16 lg:pt-0 col-span-1 row-span-1"
          />
        </div>
      </motion.div>
    </>
  );
};

export default AboutBox;

const Column = ({ header, content, additional, classNames }) => {
  return (
    <div className={classNames}>
      <h3 className="pb-4">{header}</h3>
      <RawHtml html={content} />
      {additional && <RawHtml html={additional} />}
    </div>
  );
};
