import { useStaticQuery, graphql } from "gatsby";

export const useWpAbout = () => {
  const data = useStaticQuery(graphql`
    query wpAbout {
      wpPage(slug: { eq: "about" }) {
        id
        aboutFields {
          designApproach
          expertises
          credits
          clients
          worksFeaturedOn
          contact
        }
      }
    }
  `);

  return data;
};
