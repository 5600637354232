import React from "react";

const RawHtml = ({ html }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: html?.replace("[&#8230;]", ""),
      }}
    />
  );
};

export default RawHtml;
