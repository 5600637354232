module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://hapto.giammarcogaudenzi.com/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hapto Studio - Dario Pianesi & Alessandro Sot Prepi","short_name":"Hapto Studio","description":"Hapto is a Milan-based graphic design studio, led by Alessandro Prepi Sot and Dario Pianesi. We specialize in developing compelling visual identities and innovative communication strategies for people and businesses across different sectors and scales. ","lang":"en","start_url":"/","background_color":"#D6D6D6","theme_color":"#D6D6D6","display":"minimal-ui","icon":"static/favicon.jpg","localize":[{"start_url":"/it/","lang":"it","name":"Hapto Studio - Dario Pianesi & Alessandro Sot Prepi","short_name":"Hapto Studio","description":"Hapto is a Milan-based graphic design studio, led by Alessandro Prepi Sot and Dario Pianesi. We specialize in developing compelling visual identities and innovative communication strategies for people and businesses across different sectors and scales. "}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"78509860e6b0b4c2c8a5a0b06c068a9c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
