import React, { useEffect, useState, createContext } from "react";
import useWindowSize from "../hooks/useWindowSize";
import { useWpGallery } from "../hooks/wp/useWpGallery";

export const ThemeContext = createContext({
  dark: false,
  toggleDark: () => {},
  slides: [],
  setSlides: () => {},
  activeSlide: 0,
  setActiveSlide: () => {},
  isBlack: true,
  setIsBlack: () => {},
});

export const ThemeProvider = ({ children }) => {
  const data = useWpGallery();

  const { width, height } = useWindowSize();
  const [isBlack, setIsBlack] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [slides, setSlides] = useState([]);
  const [dark, setDark] = useState(false);

  const toggleDark = () => {
    setDark(!dark);
  };

  useEffect(() => {
    if (width > height) {
      data.desktopSlides !== null && setSlides(data.desktopSlides);
    } else {
      data.mobileSlides !== null && setSlides(data.mobileSlides);
    }
  }, [width, height, data.mobileSlides, data.desktopSlides, setSlides]);

  return (
    <ThemeContext.Provider
      value={{
        dark,
        toggleDark,
        slides,
        setSlides,
        activeSlide,
        setActiveSlide,
        isBlack,
        setIsBlack,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
