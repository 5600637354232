import React from "react";

const Header = ({ isAboutOpen, goHomepage, handleAboutClick }) => {
  return (
    <header className="fixed top-0 left-0 w-full z-[2] bg-transparent">
      <nav className="flex w-full justify-between px-4 md:px-6 py-4">
        <button onClick={() => goHomepage()}>Hapto</button>
        <button
          onClick={() => (isAboutOpen ? goHomepage() : handleAboutClick())}
        >
          {isAboutOpen ? "Close" : "Studio"}
        </button>
      </nav>
    </header>
  );
};

export default Header;
