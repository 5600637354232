import { useStaticQuery, graphql } from "gatsby";

export const useWpGallery = () => {
  const data = useStaticQuery(graphql`
    query HomepageGallery {
      wpPage(slug: { eq: "homepage" }) {
        id
        projectSlideshow {
          desktopSlides {
            type
            image {
              caption
              color
              url
              image {
                altText
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AVIF, WEBP, AUTO]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
            }
            video {
              video
              caption
              color
            }
          }
          mobileSlides {
            type
            image {
              caption
              color
              url
              image {
                altText
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AVIF, WEBP, AUTO]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
            }
            video {
              video
              caption
              color
            }
          }
        }
      }
    }
  `);

  return data.wpPage.projectSlideshow;
};
